.loading {
  &_indicator {
    &.type_show {
      background: $C-black-tr35;
    }
    @extend %flexbox-v;
    z-index: $Z-loading-indicator;
    position: fixed;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  &_longtime_icon {
    border-radius: 52px;
    background-color: #fff;
    border: 12px solid $C-white;
  }
  &_message {
    padding-top: 40px;
    font-size: $F-size-xsbig;
    //font-family: $F-family-bold;
    font-weight: var(--F-weight-black);
    color: $C-white;
    line-height: 28px;
    text-align:center;
  }
  &_desc {
    padding-top: 16px;
    color: $C-white-tr70;
    font-size: $F-size;
  }
}
