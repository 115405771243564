@charset "UTF-8";

// list
.basic {
  &_list {
    &.type_col {
      @extend %flexbox-stretch;
    }
    &_wrap {

    }
    &_item {
      .type_col & {
        &:not(:first-child) {
          margin-left: 10px;
        }
        @extend %flexbox-stretch-v;
      }
    }
    &_cont {

    }
    &_main {

    }
    &_title {

    }
    &_desc {

    }
    &_side {

    }
  }
}

.menu {
  &_list {
    @extend %flexbox-stretch;
    flex-wrap: wrap;
    margin: 0 -16.67%;
    @media all and (min-width: 480px) {
      margin: 0 -12.5%;
    }
    @media all and (min-width: 600px) {
      margin: 0 -10%;
    }
    @media all and (min-width: 720px) {
      margin: 0 -8.334%;
    }
    @media all and (min-width: 840px) {
      margin: 0 -7.143%;
    }

    &_wrap {
      padding: 0 35px 30px;

    }
    &_item {
      width: 25%;
      padding-top: 30px;
      text-align: center;
      @media all and (min-width: 480px) {
        width: 20%;
      }
      @media all and (min-width: 600px) {
        width: 16.666%;
      }
      @media all and (min-width: 720px) {
        width: 14.285%;
      }
      @media all and (min-width: 840px) {
        width: 12.5%;
      }
    }
    &_cont {
      display: inline-block;
      position: relative;
      width: 70px;
      text-align: center;
    }
    &_inner {

    }

    &_main {

    }
    &_img {
      @include overflowHide();
      display: inline-block;
      box-sizing: border-box;
      width: 48px;
      height: 48px;
      border-radius: 30px;
      background: rgba(38,191,141,0.875);
      vertical-align: top;
      transform: translateZ(0);
      padding-top: 9px;
      text-align: center;
      word-break: keep-all;
      color: $C-white;
      font-size: $F-size-micro;
    }
    &_sub {
      margin: 0 -8px;
      padding-top: 8px;
      font-size: $F-size-small;
      color: $C-font-light;
      letter-spacing: -1px;
      line-height: 1.31;
    }
    &_title {
      .type_new &:after {
        @include position(0,0,null,null);
        width: 4px;
        height: 4px;
        border-radius: 3px;
        background: $C-point;
        content: '';
      }
      @include ellipsis();
      position: relative;
      box-sizing: border-box;
      padding: 0 8px;
    }
    &_desc {

    }
    &_side {

    }
  }
}
