@charset "UTF-8";

.notice {
  &_contents {
    padding: 16px 20px 20px;
    background-color: $C-white;
    min-height: 100%;
  }
  &_title{
    //font-family: $F-family-medium;
    font-weight: var(--F-weight-bold);
    margin-bottom: 22px;
  }
  &_comment{
    //font-family: $F-family-regular;
    line-height: 22px;
  }
}
