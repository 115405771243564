@charset "UTF-8";

.usage {
  &_contents {
    padding: 20px 14px 70px;
  }
  &_list {
    &_item {
      &:not(:first-child) {
        margin-top: 14px;
      }
    }
    &_image {
      @include position(50%,null,null,14px);
      transform: translateY(-50%);
    }
    &_title {
      @include ellipsis(block);
      padding-bottom: 4px;
      //font-family: $F-family-medium;
      font-weight: var(--F-weight-bold);
      font-size: $F-size;
      line-height: 1.3125;
    }
    &_desc {
      @include ellipsis(block);
      font-size: $F-size-small;
      color: $C-font-light;
      line-height: 1.31;
    }
  }

}
