@charset "UTF-8";

@font-face {
  font-family: TMOBI;
  src: url('../../assets/fonts/TMOBI_Regular.woff2') format("woff2"),
    url('../../assets/fonts/TMOBI_Regular.woff') format("woff"),
    url('../../assets/fonts/TMOBI_Regular.ttf') format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: TMOBI;
  src: url('../../assets/fonts/TMOBI_Medium.woff2') format("woff2"),
    url('../../assets/fonts/TMOBI_Medium.woff') format("woff"),
    url('../../assets/fonts/TMOBI_Medium.ttf') format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: TMOBI;
  src: url('../../assets/fonts/TMOBI_Bold.woff2') format("woff2"),
    url('../../assets/fonts/TMOBI_Bold.woff') format("woff"),
    url('../../assets/fonts/TMOBI_Bold.ttf') format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: TMOBI;
  src: url('../../assets/fonts/TMOBI_Heavy.woff2') format("woff2"),
    url('../../assets/fonts/TMOBI_Heavy.woff') format("woff"),
    url('../../assets/fonts/TMOBI_Heavy.ttf') format("truetype");
  font-weight: 800;
  font-display: swap;
}