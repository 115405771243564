@charset "UTF-8";

// buttons
.btn {
  &_wrap {
    text-align: center;
  }
  &_image {
    &_back {
      @include position(0,null,null,0);
      @include use-svg(Arrow, 52px, 55px, $wrap-position: null);
    }
  }
  &_link {
    &_defualt {

    }
    &_usage {
      @include use-svg(arrow-right-gray-16,16px,16px,50% 10px,block,right);
      padding: 18px 30px 18px 92px;
      width: 100%;
      min-height: 58px;
      background-color: $C-white;
      border-radius: 8px;
      box-sizing: border-box;
      box-shadow: 0 4px 14px 0 $C-shadow-tr20;
      font-size: $F-size;
      color: $C-black;
      line-height: 1.6875;
      text-align: left;
    }
  }
  &_plain {
    &_default {
      display: inline-block;
      vertical-align: top;
    }
  }
}
