@charset "UTF-8";

html {
  font-size: $root-font-size;
}

body {
  background: $C-gray-200;
}

body,
input,
textarea,
select,
button,
table {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  word-break: keep-all;
}
