@charset "UTF-8";

// animation
.slide-up {
  &-enter,
  &-exit-done {
    opacity: 0;
    transform: translateY(100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;
  }
  &-enter-done,
  &-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.2s ease-in-out;
  }
}

.fade-in {
  &-enter,
  &-exit-done {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: all 200ms ease-in-out;
  }
  &-enter-done,
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: all 200ms ease-in-out;
  }
}

.rising-anime {
  &-enter,
  &-leave-to {
    transform: translateY(100%);
  }

  &-enter-active,
  &-leave-active {
    @extend %anime-default;
  }
}

.fade-anime {
  &-enter,
  &-leave-to {
    opacity: 0;
    will-change: opacity;
  }

  &-enter-active,
  &-leave-active {
    @extend %anime-fade;
  }
}

.fade-page-anime {
  &-enter {
    opacity: 0;
    will-change: opacity;
  }

  &-enter-active {
    @extend %anime-fade;
  }

  &-leave {
    opacity: 0;
  }
}

.fade-header-title-anime {
  &-enter,
  &-leave-to {
    opacity: 0;
    will-change: opacity;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease-out;
    will-change: opacity;
  }
}
