@charset "UTF-8";

// $base- reset용 기본 변수입니다.
// $F- 폰트 관련 변수입니다.
// $C- 컬러 관련 변수입니다.
// $G- 글로벌 변수입니다.
// $M- Mixin 변수입니다.

// layout Width
$G-contents: 100%;
$G-contents-gap: 24px;
$G-heights: 100%;
$G-header-heights: 56px;

// Font size
$F-size-max: 3.2rem;
$F-size-xxbig: 2.8rem;
$F-size-xbig: 2.4rem;
$F-size-xsbig: 2.0rem;
$F-size-big: 1.8rem;
$F-size-basic: 1.7rem;
$F-size: 1.6rem;
$F-size-under: 1.5rem;
$F-size-normal: 1.4rem;
$F-size-small: 1.3rem;
$F-size-xsmall: 1.2rem;
$F-size-xxsmall: 1.1rem;
$F-size-mini: 1.0rem;
$F-size-micro: 0.8rem;

// Color
$C-primary: #0064ff; //primary
$C-point: #e55545; //orange-red
$C-bg: #f5f5f5; // section bg
$C-base: #f6f6f6;
$C-base-up: #f0f0f0;
$C-br: #eaeaea; //기본 버튼 프레스
$C-green: #22c335;
$C-red: #ff474b;
$C-blue-text: #2162c3;
$C-block-point: #97d4ff;

// Color
$C-gray-900: #171819;
$C-gray-850: #27292d;
$C-gray-800: #36393d;
$C-gray-700: #51565c;
$C-gray-600: #6e747a;
$C-gray-500: #8c9299;
$C-gray-400: #bec5cc;
$C-gray-300: #e0e2e8;
$C-gray-200: #eef0f3;
$C-gray-100: #f8f9fa;
$C-primary: #0064ff;
$C-tmobi-blue-600: #0052d1;
$C-tmobi-blue-500: #0064ff;
$C-tmobi-blue-400: #3d8bff;
$C-tmobi-blue-300: #cce0ff;
$C-tmobi-blue-200: #e6f1ff;
$C-tmobi-blue-100: #f5f9ff;
$C-tmobi-green-600: #038591;
$C-tmobi-green-500: #00b2b2;
$C-tmobi-green-400: #2dccc6;
$C-tmobi-green-300: #beedec;
$C-tmobi-green-200: #dff6f5;
$C-tmobi-green-100: #f1fbfb;
$C-tmobi-pink-600: #c9388d;
$C-tmobi-pink-500: #fa32aa;
$C-tmobi-pink-400: #ff70c8;
$C-tmobi-pink-300: #ffdbf4;
$C-tmobi-pink-200: #ffe9f9;
$C-tmobi-pink-100: #fef7fc;
$C-tmobi-purple-600: #5b2cc7;
$C-tmobi-purple-500: #773efa;
$C-tmobi-purple-400: #976cfb;
$C-tmobi-purple-300: #e3d8fe;
$C-tmobi-purple-200: #f1ebfe;
$C-tmobi-purple-100: #f9f7ff;
$C-red-600: #a63019;
$C-red-500: #f24724;
$C-red-400: #fa5938;
$C-red-200: #fde8e4;
$C-red-100: #fef7f6;
$C-orange-600: #b86e00;
$C-orange-500: #ff9000;
$C-orange-400: #ffab3f;
$C-orange-200: #fff0d9;
$C-orange-100: #fffbf5;
$C-yellow-600: #d9a600;
$C-yellow-500: #ffc219;
$C-yellow-400: #ffd26a;
$C-yellow-200: #fff3d1;
$C-yellow-100: #fffbf1;
$C-green-600: #1b853e;
$C-green-500: #25b856;
$C-green-400: #3fcb71;
$C-green-200: #daf5e5;
$C-green-100: #f3fcf6;
$C-ev-blue-600: #007fad;
$C-ev-blue-500: #00ace5;
$C-ev-blue-400: #2bc0f8;
$C-ev-blue-200: #ddf5fb;
$C-ev-blue-100: #f4fcfe;

// Font color
$C-font-light: #777777; //밝은 폰트컬러
$C-font-dark: #27292a; //어두운 폰트컬러
$C-font-dim: #dddddd; //흐린 폰트컬러

//basic color
$C-tr: transparent; //투명
$C-white: #ffffff; //흰색
$C-black: #000000; //검정색

//rgba
$C-shadow-tr20: rgba(191,201,214,.2);
$C-black-tr07: rgba(0, 0, 0, 0.07);
$C-black-tr10: rgba(0, 0, 0, 0.1);
$C-black-tr20: rgba(0, 0, 0, 0.20);
$C-black-tr35: rgba(0, 0, 0, 0.35);
$C-black-tr40: rgba(0, 0, 0, 0.4);
$C-black-tr55: rgba(0, 0, 0, 0.55);
$C-black-tr68: rgba(0, 0, 0, 0.68);
$C-black-tr: rgba(0, 0, 0, 0);
$C-white-tr70: rgba(255, 255, 255, 0.7);

$Z-floating-navigation: 1900;
$Z-header: 2000;
$Z-layer: 2100;
$Z-loading-indicator: 2200;

// Base Font Style
$root-font-size: 10px;
$base-font-color: $C-font-dark;
$base-font-family: TMOBI, sans-serif !default;
$base-font-size: $F-size;
$base-line-height: 1.3;
$base-font-weight: var(--F-weight-normal) !default;
