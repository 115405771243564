@charset "UTF-8";

.wrap {
  //overflow-x: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 0; // chrome 72 flex issue
  -webkit-overflow-scrolling: touch;
}

// iOS 11.0-11.2
@supports (padding-bottom: constant(safe-area-inset-bottom)) {
  //.wrap {
  //  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  //  min-height: calc(100% + var(--safe-area-inset-bottom));
  //}
  //.wrap {
  //  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  //  height: calc(100% + var(--safe-area-inset-bottom));
  //}
}

// iOS 11.2+
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  //.wrap {
  //  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  //  height: calc(100% + var(--safe-area-inset-bottom));
  //  //height: calc(100%);
  //}
  //.wrap {
  //  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  //  height: calc(100% + var(--safe-area-inset-bottom));
  //}
}

.container {
  width: 100%;
  height: 100%;
  padding-top: $G-header-heights;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  &.type_full {
    padding-top: 0;
    height: 100%;
  }
}

.header {
  @include position(0, 0, null, 0, $Z-header);
  height: $G-header-heights;
  background: $C-white;

  &.type_scroll {
    border-bottom: 1px solid $C-gray-200;
  }
  &.type_fix {
    position: fixed;
  }
  &_main {
    &_wrap {
      position: relative;
    }
    &_title {
      @include ellipsis(block);
      height: 56px;
      padding: 0 56px;
      //font-family: $F-family-medium;
      font-weight: var(--F-weight-bold);
      font-size: $F-size-big;
      line-height: 3.12;
      text-align: center;
    }
  }
  &_content {
    &_title {
      @include ellipsis(block);
      height: $G-header-heights;
      padding: 16px 56px;
      //font-family: $F-family-medium;
      font-weight: var(--F-weight-bold);
      font-size: $F-size;
      line-height: $G-header-heights;
      text-align: center;
    }
  }
}

.contents {
  background-color: $C-bg;
  transition: padding-bottom .2s ease;

  &.type {
    &_bottom_banner {
      padding-bottom: 86px;
    }
  }
}

.article {
  -webkit-overflow-scrolling: touch;
  background: $C-bg;
  width: 100%;
}

.noti_on {
  &:after {
    @include position(12px, 12px);
    width: 8px;
    height: 8px;
    background: $C-point;
    content: '';
    border-radius: 50%;
  }
}
