/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2022 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 * http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --G-layout-margin: 20px;
  --Z-modal-loading: 10020;
  --Z-modal-layer: 10010;
  --Z-modal-dimmed: 10000;
  --Z-sticky: 120;
  --Z-default: 1;
  --Z-below: -1;
  --F-weight-normal: 400;
  --F-weight-medium: 500;
  --F-weight-bold: 600;
  --F-weight-black: 900;
  
  --gray-color-gray-900: #171819;
  --gray-color-gray-800: #36393d;
  --gray-color-gray-700: #51565c;
  --gray-color-gray-600: #6e747a;
  --gray-color-gray-500: #8c9299;
  --gray-color-gray-400: #bec5cc;
  --gray-color-gray-300: #e0e2e8;
  --gray-color-gray-200: #eef0f3;
  --gray-color-gray-100: #f8f9fa;
  --gray-color-wb-0: #fff;
  --gray-color-wb-1000: #000;
  --gray-color-wb-transparent-700: rgba(0, 0, 0, 0.6);
  --gray-color-wb-transparent-300: rgba(0, 0, 0, 0.1);
  --gray-color-wb-transparent-100: rgba(0, 0, 0, 0.04);
  --primary-color-tmobi-blue-600: #0052d1;
  --primary-color-tmobi-blue-500: #0064ff;
  --primary-color-tmobi-blue-400: #3d8bff;
  --primary-color-tmobi-blue-300: #cce0ff;
  --primary-color-tmobi-blue-200: #e6f1ff;
  --primary-color-tmobi-blue-100: #f5f9ff;
  --seconary-color-tmobi-green-600: #038591;
  --seconary-color-tmobi-green-500: #00b2b2;
  --seconary-color-tmobi-green-400: #2dccc6;
  --seconary-color-tmobi-green-300: #beedec;
  --seconary-color-tmobi-green-200: #dff6f5;
  --seconary-color-tmobi-green-100: #f1fbfb;
  --seconary-color-tmobi-pink-600: #c9388d;
  --seconary-color-tmobi-pink-500: #fa32aa;
  --seconary-color-tmobi-pink-400: #ff70c8;
  --seconary-color-tmobi-pink-300: #ffdbf4;
  --seconary-color-tmobi-pink-200: #ffe9f9;
  --seconary-color-tmobi-pink-100: #fef7fc;
  --seconary-color-tmobi-purple-600: #5b2cc7;
  --seconary-color-tmobi-purple-500: #773efa;
  --seconary-color-tmobi-purple-400: #976cfb;
  --seconary-color-tmobi-purple-300: #e3d8fe;
  --seconary-color-tmobi-purple-200: #f1ebfe;
  --seconary-color-tmobi-purple-100: #f9f7ff;
  --semantic-color-red-600: #a63019;
  --semantic-color-red-500: #f24724;
  --semantic-color-red-200: #fde8e4;
  --semantic-color-red-100: #fef7f6;
  --semantic-color-orange-600: #b86e00;
  --semantic-color-orange-500: #ff9000;
  --semantic-color-orange-200: #fff0d9;
  --semantic-color-orange-100: #fffbf5;
  --semantic-color-yellow-600: #d9a600;
  --semantic-color-yellow-500: #ffc219;
  --semantic-color-yellow-200: #fff3d1;
  --semantic-color-yellow-100: #fffbf1;
  --semantic-color-green-600: #1b853e;
  --semantic-color-green-500: #25b856;
  --semantic-color-green-200: #daf5e5;
  --semantic-color-green-100: #f3fcf6;
  --semantic-color-ev-blue-600: #008cbf;
  --semantic-color-ev-blue-500: #00b2db;
  --semantic-color-ev-blue-200: #ddf5fb;
  --semantic-color-ev-blue-100: #f4fcfe;
}
