@charset "UTF-8";

// section
.basic_section {
  position: relative;
  padding: 16px 16px 36px;

  &:last-child {
    padding-bottom: 32px;
  }

  &.type_event {
    padding: (48-20)+px 16px 20px;
  }

  &_title {
    font-size: $F-size-normal;
    //font-family: $F-family-medium;
    font-weight: var(--F-weight-bold);
    line-height: 16px;
    margin-bottom: 16px;
    padding-top: 24px;
    .type_wide &,
    .type_wide_bottom &,
    .type_flat & {
      padding: 24px 20px 0;
    }
  }
  &_sub {
    margin-left: 1px;
    //font-family: $F-family-regular;
    font-size: $F-size-xsmall;
    color: $C-font-light;
  }

  &_comment {
    position: relative;
    font-size: $F-size-xsbig;
    color: $C-font-light;
    line-height: 28px;
    letter-spacing: -0.5px;
    & .type_under {
      position: relative;
      z-index: 1;
      &:after {
        @include position(null, 0, 0, 0, -1);
        background: $C-block-point;
        height: 10px;
        content: '';
      }
    }
    & .type_point {
      color: $C-font-dark;
      //font-family: $F-family-medium;
      font-weight: var(--F-weight-bold);
    }

    .type_wide & {
      padding: 0 20px;
    }
    .type_wide_bottom & {
      margin: 0 20px;
    }
  }
  &_util {
    @include position(null, 0, 0);
  }
  &_foot {
    padding-top: 16px;
    text-align: center;
  }
  &_cont {
    position: relative;
  }
  &_desc {
    padding-top: 16px;
    font-size: $F-size;
    color: $C-font-dark;
    line-height: 1.25;
  }
}

.sub_section {
  &_wrap {
    padding: 24px 0 48px;
    background: $C-white;
  }
  &_comment {
    background: $C-white;
    font-size: $F-size-xsbig;
    color: $C-font-light;
    line-height: 28px;
    letter-spacing: -0.5px;
    position: relative;
    margin: 0 20px 48px;
    & .type_under {
      position: relative;
      z-index: 1;
      &:after {
        @include position(null, 0, 0, 0, -1);
        background: $C-block-point;
        height: 10px;
        content: '';
      }
    }
    & .type_point {
      color: $C-font-dark;
      //font-family: $F-family-medium;
      font-weight: var(--F-weight-bold);
    }
  }
}
